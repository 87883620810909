import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpResponse, HttpErrorResponse, HttpClient
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthService} from './auth.service';
import {catchError, delay, retry, take, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';


const BASE_URL = environment.apiUrl;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

    constructor(
        public auth: AuthService,
        private http: HttpClient
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = this.auth.currentUserValue?.accessToken;
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.auth.currentUserValue?.accessToken}`,
                }
            });
        }


        return next.handle(request)
            .pipe(
                tap((event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                        }
                    }, e => {
                        if (e.status === 401) {
                            this.http.post(`${BASE_URL}/v1/auth/refreshtoken`, {token: this.auth.currentUserValue?.refreshToken})
                                .subscribe(token => {
                                    if (token['status'] === 'SUCCESS') {
                                        this.auth.currentUserValue.accessToken = token['data'].accessToken;
                                        this.auth.currentUserValue.refreshToken = token['data'].refreshToken;
                                        request = request.clone({
                                            setHeaders: {
                                                Authorization: `Bearer ${this.auth.currentUserValue?.accessToken}`,
                                            }
                                        });
                                    }
                                }, error => {
                                    if (error.status === 401) {
                                        localStorage.removeItem(this.authLocalStorageToken);
                                        this.auth.currentUserSubject.next(null);
                                        location.reload(true);

                                    }
                                });
                        }
                    }
                ), take(2));
    }
}
